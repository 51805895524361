import LiquorTree from 'liquor-tree'
import Vue, { defineComponent, type PropType } from 'vue'

import type { ActionElements } from '@/javascript/interfaces'
import DataTableActions from '@/javascript/vuejs/components/shared/DataTableActions'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import type { ContentPackageFolder, PackageType } from '@/javascript/vuejs/services/packages/interfaces'

import { stringToNumber } from '../packages/utils'

Vue.use(LiquorTree)

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'action': (action: string, id?: number | string | undefined, type?: PackageType): boolean => true,
	'bulk': (checkedNodes: CheckedNodes[]): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'TreeView',

	props: {
		...on(emits),

		additionalClasses: { default: '', type: String },
		autoCheckChildren: { default: true, type: Boolean },
		checkOnSelect: { default: false, type: Boolean },
		checkbox: { default: false, type: Boolean },
		data: { required: true, type: Array as PropType<Folder[]> },
		dataTest: { default: 'tree-view', type: String },
		message: { default: '', type: String },
		multiple: { default: true, type: Boolean },
		parentSelect: { default: false, type: Boolean },
	},

	emits,

	data(): Data {
		return {
			checkedTreeNodes: [],
			options: {
				autoCheckChildren: this.autoCheckChildren,
				checkOnSelect: this.checkOnSelect,
				checkbox: this.checkbox,
				dataTest: this.dataTest,
				keyboardNavigation: true,
				multiple: this.multiple,
				parentSelect: this.parentSelect,
			},
		}
	},

	methods: {
		handleOnActionClick(event: Event, action: string, id: number | string | undefined, type?: PackageType): void {
			event.stopPropagation()
			this.$emit('action', action, !!id ? stringToNumber(id) : undefined, type)
		},

		onHandleEvent(event: NodeEvent<ContentPackageFolder>): void {
			this.checkedTreeNodes = event.checked?.map(i => ({
				id: i.data.id,
				type: i.data.type,
			}))

			this.$emit('bulk', this.checkedTreeNodes)
		},
	},

	render() {
		return (
			<div data-test={this.dataTest}>
				<tree
					class={this.additionalClasses}
					data={this.data}
					options={this.options}
					ref="tree"
					onInput={this.onHandleEvent}
					scopedSlots={{
						default: ({ node }: { node: Folder }): any =>
							<div class="node-info" data-test="tree-view-row">
								{node.data.type === 'content_package_folder' &&
									<i class="fa fa-folder-blank" />
								}
								<span class="tree-anchor" data-test={node.id}>
									{node.text} {node.state.checked}
								</span>

								{node.data.actions &&
									<span class="node-actions">
										{node.data.actions.length > 1 ?
											<DataTableActions
												actions={node.data.actions}
												id={`${node.data.id}`}
												onAction={(action: string, id: number, type?: PackageType): void =>
													this.$emit('action', action, id, type)}
											/>

											:

											<a
												data-package-folder-id={node.id}
												data-test={node.data.actions[0].dataTest}
												v-tooltip={node.data.actions[0].tooltip}
												href={node.data.actions[0].link ?? 'javascript:void(0)'}
												class="custom-menu__control-button"
												onClick={(event): void =>
													this.handleOnActionClick(event, node.data.actions[0].text ?? '', node.id, node.data.type)}
											>
												<i aria-hidden="true" class={['fa', node.data.actions[0].icon]} />
											</a>
										}
									</span>
								}
							</div>,
					}}
				/>

				{!this.data && <p class="no-results">{this.message}</p>}
			</div>
		)
	},
})

export interface CheckedNodes {
	id: number | string | undefined
	name?: string
	type: PackageType | string | undefined
}

export interface Folder {
	children?: {
		data: DataOptions
		id: number | string | undefined
		state: NodeStates
		text: string
		type: PackageType
	}[]
	data: DataOptions
	id: number | string | undefined
	state: NodeStates
	text: string
	type: PackageType
}

export interface NodeStates {
	checked: boolean
	visible: boolean
}

interface Data {
	checkedTreeNodes: CheckedNodes[]
	options: {
		autoCheckChildren: boolean
		checkOnSelect: boolean
		checkbox: boolean
		dataTest: string
		keyboardNavigation: boolean
		multiple: boolean
		parentSelect: boolean
	}
}

interface DataOptions {
	actions: ActionElements[]
	id: number | string
	type: PackageType
}

interface Node<T> {
	data: T
}

interface NodeEvent<T> {
	checked: Node<T>[]
}
